import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import { Link } from "react-router-dom";

const IndustryCarousel = () => {
    const settings = {
        dots: false,
        infinite: true,
        speed: 1000,
        arrows: true,
        autoplay: false,
        slidesToShow: 8,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1025,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            }
        ],
        prevArrow: <span className="slider-btn slider-prev"><FiChevronLeft /></span>,
        nextArrow: <span className="slider-btn slider-next"><FiChevronRight /></span>,
        appendArrows: "#carausel-8-columns-arrows"
    };

    const industries = [
        { title: "Agricultural", icon: "assets/imgs/theme/icons/agricultural.svg" },
        { title: "Chemicals", icon: "assets/imgs/theme/icons/chemicals.svg" },
        { title: "Electrical Equipment & Supplies", icon: "assets/imgs/theme/icons/electrical-equipment&supplies.svg" },
        { title: "Apparel", icon: "assets/imgs/theme/icons/apparel.svg" },
        { title: "Construction", icon: "assets/imgs/theme/icons/Constraction.svg" },
        { title: "Energy", icon: "assets/imgs/theme/icons/energy.svg" },
        { title: "Beauty & Personal Care", icon: "assets/imgs/theme/icons/beauty.svg" },
        { title: "Consumer Electronics", icon: "assets/imgs/theme/icons/consumer-electronics.svg" },
        { title: "Fabrication Services", icon: "assets/imgs/theme/icons/fabrication.svg" },
        { title: "Home Appliance", icon: "assets/imgs/theme/icons/home-appliance.svg" },
        { title: "Lights & Lighting", icon: "assets/imgs/theme/icons/lights.svg" }
    ];

    return (
        <>
            <div className="section-title">
                <div className="title">
                    <h3>We serve a diverse range of industries</h3>
                    {/* <a className="show-all" href="#">
                        All Industries
                        <i className="fi-rs-angle-right"></i>
                    </a> */}
                </div>
                <div className="slider-arrow slider-arrow-2 flex-right carausel-8-columns-arrow" id="carausel-8-columns-arrows"></div>
            </div>
            <div className="carausel-8-columns-cover position-relative">
                <Slider {...settings} className="carausel-8-columns">
                    {industries.map((industry, index) => (
                        <div key={index} className="card-1">
                            <figure className="img-hover-scale overflow-hidden">
                                <a href="shop-grid-right.html">
                                    <img src={industry.icon} alt={industry.title} />
                                </a>
                            </figure>
                            <h6>
                                <Link to="/">{industry.title}</Link>
                            </h6>
                        </div>
                    ))}
                </Slider>
            </div>
        </>
    );
};

export default IndustryCarousel;

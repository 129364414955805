import React, { useState } from "react";
import emailjs from "emailjs-com";

const Contact = () => {
	const [formData, setFormData] = useState({
		firstName: "",
		email: "",
		phone: "",
		subject: "",
		message: "",
	});

    const [loading, setLoading] = useState(false);  // To manage loading state
    const [message, setMessage] = useState("");     // To manage success or error message

  const handleChange = (e) => {
    const { name, value } = e.target;

    // If the field is phone, only allow numbers
    if (name === "phone") {
      // Remove any non-numeric characters
      const numericValue = value.replace(/\D/g, "");
      setFormData({
        ...formData,
        [name]: numericValue,
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true); // Show loader
    setMessage("");   // Clear any previous messages

    // You can change the service_id, template_id, and user_id to your EmailJS details
    const serviceID = "service_1chm2p9";
    const templateID = "template_fm2mxld";
    const userID = "LEJQW3KxrQk30vQzt";

    emailjs
      .send(serviceID, templateID, formData, userID)
      .then(
        (response) => {
          setLoading(false); // Hide loader
          setMessage("Your message has been sent successfully!"); // Show success message

          // Hide the message after 10 seconds and clear the form
          setTimeout(() => {
            setMessage(""); // Hide message
            setFormData({ firstName: "", email: "", phone: "", subject: "", message: "" }); // Clear form
          }, 3000); // 10 seconds delay
        },
        (error) => {
          setLoading(false); // Hide loader
          setMessage("There was an error sending your message. Please try again later."); // Show error message

          // Hide the message after 10 seconds
          setTimeout(() => {
            setMessage(""); // Hide message
          }, 3000); // 10 seconds delay
        }
      );
  	};

    return (
        <>
        <section className="hero-3 position-relative align-items">
            <h2 className="mb-30 text-center">Contact Us</h2>
        </section>
        <div className="page-content pt-50">
            <div className="container">
                <div className="row">
                    <div className="col-xl-10 col-lg-12 m-auto">
                        <section className="mb-50">
                            <div className="row mb-60">
                                <div className="col-md-4 mb-4 mb-md-0">
                                    <h4 className="mb-15 text-brand">Office</h4>
                                    Unit No 17, Building No 02, C Wing, Gaondevi Co-operative Society, New Link Road, Goregoan (W) Mumbai - 400104<br />
                                    <abbr title="Phone">Phone:</abbr> +91 95947 81570<br />
                                    <abbr title="Email">Email: </abbr>sales@procurelogindia.com<br />
                                </div>
                                {/* <div className="col-md-4 mb-4 mb-md-0">
                                    <h4 className="mb-15 text-brand">Studio</h4>
                                    205 North Michigan Avenue, Suite 810<br />
                                    Chicago, 60601, USA<br />
                                    <abbr title="Phone">Phone:</abbr> (123) 456-7890<br />
                                    <abbr title="Email">Email: </abbr>contact@Evara.com<br />
                                </div>
                                <div className="col-md-4">
                                    <h4 className="mb-15 text-brand">Shop</h4>
                                    205 North Michigan Avenue, Suite 810<br />
                                    Chicago, 60601, USA<br />
                                    <abbr title="Phone">Phone:</abbr> (123) 456-7890<br />
                                    <abbr title="Email">Email: </abbr>contact@Evara.com<br />
                                </div> */}
                            </div>
                            <div className="row">
                                <div className="col-xl-8">
                                    <div className="contact-from-area padding-20-row-col">
                                        {/* <h5 className="text-brand mb-10">Contact form</h5> */}
                                        <h2 className="mb-10">Drop Us a Line</h2>
                                        <p className="text-muted mb-30 font-sm">Your email address will not be published. Required fields are marked *</p>
                                        
                                        {message && <p className={`message ${message.includes("error") ? "error" : ""}`}>{message}</p>}
                                        <form className="contact-form-style mt-30" onSubmit={handleSubmit}>
                                            <div className="row">
                                                <div className="col-lg-6 col-md-6">
                                                    <div className="input-style mb-20">
														<input
															placeholder="First Name"
															type="text"
															id="firstName"
															name="firstName"
															value={formData.firstName}
															onChange={handleChange}
															required
														/>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-md-6">
                                                    <div className="input-style mb-20">
														<input
															placeholder="Your Email"
															type="email"
															id="email"
															name="email"
															value={formData.email}
															onChange={handleChange}
															required
														/>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-md-6">
                                                    <div className="input-style mb-20">
														<input
															placeholder="Your Phone"
															type="tel"
															id="phone"
															name="phone"
															value={formData.phone}
															onChange={handleChange}
                                                            pattern="[0-9]*" 
															required
														/>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-md-6">
                                                    <div className="input-style mb-20">
														<input
															placeholder="Subject"
															type="text"
															id="subject"
															name="subject"
															value={formData.subject}
															onChange={handleChange}
															required
														/>
                                                    </div>
                                                </div>
                                                <div className="col-lg-12 col-md-12">
                                                    <div className="textarea-style mb-30">
														<textarea
															placeholder="Message"
															id="message"
															name="message"
															value={formData.message}
															onChange={handleChange}
															required
														/>
                                                    </div>
                                                    <button className="submit submit-auto-width" type="submit" disabled={loading}>
                                                        {loading ? "Sending..." : "Send Message"}
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                        <p className="form-messege"></p>
                                        {/* Loading spinner */}
                                        {/* {loading && <div className="loader">Sending...</div>} */}
                                    </div>
                                </div>
                                <div className="col-lg-4 pl-50 d-lg-block d-none">
                                    <img className="border-radius-15 mt-50" src="assets/imgs/page/contact-2.png" alt="" />
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}

export default Contact

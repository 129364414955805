import React from 'react'

function LogisticsSupport() {
  return (
		<>
		<section className="logistics-support position-relative">
			<h2 className="mb-30 text-center">Logistics Support</h2>
		</section>

		<div className="page-content pt-50">
			<div className="container">
				<div className="row">
					<div className="col-xl-10 col-lg-12 m-auto">
						<section className="pb-5">
							<div className="container">
								<div className="section-title wow animate__animated animate__fadeIn" data-wow-delay="0">
									<h5 className="text-center w-100">Procure Log India excels in providing full logistics support to importerswho acquire supplies from our website. <br/>Here's how they usually assist:</h5>
								</div>
								<div className="row">
									<div className="col-xl-4 col-lg-4 col-md-6">
										<div className="product-cart-wrap style-2 wow animate__animated animate__fadeInUp" data-wow-delay="0">
											<div className="product-img-action-wrap">
												<div className="product-img">
													<a href="shop-product-right.html">
														<img src="assets/imgs/page/lg1.png" alt="" />
													</a>
												</div>
											</div>
											<div className="product-content-wrap d-flex">
												<div className="deals-content text-center">
													<h5 className="mb-10">Shipping<br/>Coordination</h5>
													<p>Procure Log India handles the logistics of transporting acquired products from the supplier's location to the importer's preferred destination. This entails working with shipping lines, aircraft carriers, and logistics organizations to plan the most efficient and cost-effective transportation options.</p>
												</div>
											</div>
										</div>
									</div>

									<div className="col-xl-4 col-lg-4 col-md-6">
										<div className="product-cart-wrap style-2 wow animate__animated animate__fadeInUp" data-wow-delay="0">
											<div className="product-img-action-wrap">
												<div className="product-img">
													<a href="shop-product-right.html">
														<img src="assets/imgs/page/lg2.png" alt="" />
													</a>
												</div>
											</div>
											<div className="product-content-wrap d-flex">
												<div className="deals-content text-center">
													<h5 className="mb-10">Customs <br/>Clearance Assistance</h5>
													<p>Procure Log India helps importers complete customs clearance requirements smoothly. They guarantee that all required documentation, such as invoices, packing lists, and import licenses, is properly produced and immediately filed to customs officials.</p>
												</div>
											</div>
										</div>
									</div>

									<div className="col-xl-4 col-lg-4 col-md-6">
										<div className="product-cart-wrap style-2 wow animate__animated animate__fadeInUp" data-wow-delay="0">
											<div className="product-img-action-wrap">
												<div className="product-img">
													<a href="shop-product-right.html">
														<img src="assets/imgs/page/lg3.png" alt="" />
													</a>
												</div>
											</div>
											<div className="product-content-wrap d-flex">
												<div className="deals-content text-center">
													<h5 className="mb-10">Import Duties <br/>and Taxes Calculation</h5>
													<p>Procure Log India assists importers in understanding and calculating appropriate import duties, taxes, and other expenses involved with importing materials. They advise on tariff classifications, preferential trade agreements, and duty optimization measures to reduce import costs.</p>
												</div>
											</div>
										</div>
									</div>

									<div className="col-xl-4 col-lg-4 col-md-6">
										<div className="product-cart-wrap style-2 wow animate__animated animate__fadeInUp" data-wow-delay="0">
											<div className="product-img-action-wrap">
												<div className="product-img">
													<a href="shop-product-right.html">
														<img src="assets/imgs/page/lg4.png" alt="" />
													</a>
												</div>
											</div>
											<div className="product-content-wrap">
												<div className="deals-content text-center">
													<h5 className="mb-10">Warehouse <br/>Solutions</h5>
													<p>Procure Log India may provide warehouse services to importers who need storage space for their supplies. They oversee the reception, storage, and administration of inventory, assuring prompt delivery to the importer's site as necessary.</p>
												</div>
											</div>
										</div>
									</div>

									<div className="col-xl-4 col-lg-4 col-md-6">
										<div className="product-cart-wrap style-2 wow animate__animated animate__fadeInUp" data-wow-delay="0">
											<div className="product-img-action-wrap">
												<div className="product-img">
													<a href="shop-product-right.html">
														<img src="assets/imgs/page/lg5.png" alt="" />
													</a>
												</div>
											</div>
											<div className="product-content-wrap">
												<div className="deals-content text-center">
													<h5 className="mb-10">Inventory <br/>Management</h5>
													<p>Procure Log India helps importers manage their inventory levels properly. They offer real-time visibility into stock levels, replenishment plans, and demand forecasts, allowing you to manage inventory holding costs and fulfill consumer demand more efficiently.</p>
												</div>
											</div>
										</div>
									</div>

									<div className="col-xl-4 col-lg-4 col-md-6">
										<div className="product-cart-wrap style-2 wow animate__animated animate__fadeInUp" data-wow-delay="0">
											<div className="product-img-action-wrap">
												<div className="product-img">
													<a href="shop-product-right.html">
														<img src="assets/imgs/page/lg6.png" alt="" />
													</a>
												</div>
											</div>
											<div className="product-content-wrap">
												<div className="deals-content text-center">
													<h5 className="mb-10">Supply <br/>Chain Optimization</h5>
													<p>Procure Log India collaborates with importers to streamline supply chain procedures and increase overall efficiency. This might include simplifying transportation routes, consolidating shipments, or using just-in-time inventory methods to cut lead times and increase cost-effectiveness.</p>
												</div>
											</div>
										</div>
									</div>

									<div className="col-xl-4 col-lg-4 col-md-6">
										<div className="product-cart-wrap style-2 wow animate__animated animate__fadeInUp" data-wow-delay="0">
											<div className="product-img-action-wrap">
												<div className="product-img">
													<a href="shop-product-right.html">
														<img src="assets/imgs/page/lg7.png" alt="" />
													</a>
												</div>
											</div>
											<div className="product-content-wrap"> 
												<div className="deals-content text-center">
													<h5 className="mb-10">Customer <br/>Help</h5>
													<p>Procure Log India provides continuing customer help for any questions or difficulties with logistics, shipping, or import processes. They provide as a single point of contact for importers, offering prompt help and addressing any logistical issues that may emerge.</p>
												</div>
											</div>
										</div>
									</div>

									<div className="col-xl-8 col-lg-4 col-md-6 single-content">
										<div className="product-cart-wrap style-2 wow animate__animated animate__fadeInUp" data-wow-delay="0">
											<blockquote>
												<p className="f-16">
													By offering full logistics assistance, Procure Log India allows importers to focus on their main business operations while assuring the smooth passage of commodities from source to destination. Their competence in international commerce and logistics management increases efficiency and decreases risk and ultimately contributes to the importer's success in the global marketplace.
												</p>
											</blockquote>
										</div>
									</div>
								</div>

								
							</div>
						</section>
					</div>
				</div>
			</div>
		</div>
		</>
  )
}

export default LogisticsSupport

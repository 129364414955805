import React from 'react'
import { Link } from 'react-router-dom';
import { GiChemicalDrop } from "react-icons/gi";
import { MdEngineering } from "react-icons/md";
import { MdOutlinePrecisionManufacturing } from "react-icons/md";
import { MdOutlineHealthAndSafety } from "react-icons/md";
import { GrTechnology } from "react-icons/gr";
import { SlEnergy } from "react-icons/sl";
import { MdEmojiTransportation } from "react-icons/md";
import { MdConstruction } from "react-icons/md";
import { MdAgriculture } from "react-icons/md";
import { FaCar } from "react-icons/fa6";
import { MdOutlineConnectingAirports } from "react-icons/md";
import { GiMining } from "react-icons/gi";
import { TiWorld } from "react-icons/ti";

                
function Navbar() {
  return (
    <>
        <header className="header-area header-style-1 header-height-2">
            <div className="mobile-promotion">
                <span>Grand opening, <strong>up to 15%</strong> off all items. Only <strong>3 days</strong> left</span>
            </div>

            <div className="header-bottom header-bottom-bg-color sticky-bar">
                <div className="container">
                    <div className="header-wrap header-space-between position-relative">
                        <div className="logo logo-width-1 d-block">
                            <Link to="/"><img src="assets/imgs/theme/logo-2.svg" alt="logo" /></Link>
                        </div>
                        <div className="header-nav d-none d-lg-flex">
                            <div className="main-categori-wrap d-none d-lg-block">
                                <Link to="/" className="categories-button-active">
                                    <span className="fi-rs-apps"></span> <span className="et">All</span>Sectors
                                    <i className="fi-rs-angle-down"></i>
                                </Link>
                                <div className="categories-dropdown-wrap categories-dropdown-active-large font-heading">
                                    <div className="d-flex categori-dropdown-inner">
                                        <ul>
                                            <li>
                                                <Link to="/"> <GiChemicalDrop className='sector-ico' />Chemical</Link>
                                            </li>
                                            <li>
                                                <Link to="/"> <MdEngineering className='sector-ico' />Engineering</Link>
                                            </li>
                                            <li>
                                                <Link to="/"> <MdOutlinePrecisionManufacturing className='sector-ico' />Manufacturing</Link>
                                            </li>
                                            <li>
                                                <Link to="/"> <MdOutlineHealthAndSafety className='sector-ico' /> Healthcare</Link>
                                            </li>
                                            <li>
                                                <Link to="/"> <GrTechnology className='sector-ico' />Technology</Link>
                                            </li>
                                        </ul>
                                        <ul className="end">
                                            <li>
                                                <Link to="/"><SlEnergy className='sector-ico' />Energy</Link>
                                            </li>
                                            <li>
                                                <Link to="/"><MdEmojiTransportation className='sector-ico' />Transportation & Logistics</Link>
                                            </li>
                                            <li>
                                                <Link to="/"><MdConstruction className='sector-ico' />Construction</Link>
                                            </li>
                                            <li>
                                                <Link to="/"><MdAgriculture className='sector-ico'/>Agriculture & Food</Link>
                                            </li>
                                            <li>
                                                <Link to="/"> <FaCar className='sector-ico' />Automotive</Link>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="more_slide_open" style={{display: 'none'}}>
                                        <div className="d-flex categori-dropdown-inner">
                                            <ul>
                                                <li>
                                                    <Link to="/"><MdOutlineConnectingAirports className='sector-ico' />Aerospace & Defense</Link>
                                                </li>
                                                <li>
                                                    <Link to="/"> <GiMining className='sector-ico' /> Mining & Metals</Link>
                                                </li>
                                            </ul>
                                            <ul className="end">
                                                <li>
                                                    <Link to="/"><TiWorld className='sector-ico'/>Environmental</Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="more_categories"><span className="icon"></span> <span className="heading-sm-1">Show more...</span></div>
                                </div>
                            </div>
                            <div className="main-menu main-menu-padding-1 main-menu-lh-2 d-none d-lg-block font-heading">
                                <nav>
                                    <ul>
                                        <li className="position-static">
                                            <Link to="/">Our Solutions <i className="fi-rs-angle-down"></i></Link>
                                            <ul className="mega-menu">
                                                <li className="sub-mega-menu sub-mega-menu-width-22">
                                                    <Link to="/" className="menu-title" >My Bid My Price</Link>
                                                    <p>Lets buyers set a target price, and sellers respond directly, skipping negotiations for a faster deal.</p>

                                                    <br /><br />
                                                    <Link to="/" className="menu-title" >Turnkey Project</Link>
                                                    <p>Helps clients find the best professionals with proven track records in their respective industries, offering end-to-end management from planning to execution for seamless project delivery.</p>
                                                </li>
                                                <li className="sub-mega-menu sub-mega-menu-width-22">
                                                    <Link to="/" className="menu-title" >International Supplier Branding</Link>
                                                    <p>Craft strategies for global market expansion, ensuring consistent brand identity across regions.</p>

                                                    <br /><br />
                                                    <Link to="/" className="menu-title" >Sourcing Request</Link>
                                                    <p>Streamlines procurement with RFQ submissions and direct seller communication for faster, transparent negotiations and offers.</p>
                                                </li>
                                                <li className="sub-mega-menu sub-mega-menu-width-22">
                                                    <Link to="/" className="menu-title" >B2B marketplaces</Link>
                                                    <p>Enable companies to easily discover, connect, and transact with other businesses on a single platform.</p>

                                                    <br /><br />
                                                    <Link to="/" className="menu-title" >Logistics</Link>
                                                    <p>Logistics services offer real-time quotes, a freight calculator for cost estimates, and online tracking for live shipment updates, making shipping more efficient and transparent.</p>
                                                </li>
                                                <li className="sub-mega-menu sub-mega-menu-width-34">
                                                    <div className="menu-banner-wrap">
                                                        <Link to="/" ><img src="assets/imgs/banner/banner-menu.png" alt="Nest" /></Link>
                                                        {/*<div className="menu-banner-content">
                                                            <h4>Hot deals</h4>
                                                            <h3>
                                                                Don't miss<br />
                                                                Trending
                                                            </h3>
                                                            <div className="menu-banner-price">
                                                                <span className="new-price text-success">Save to 50%</span>
                                                            </div>
                                                            <div className="menu-banner-btn">
                                                                <Link to="/" >Shop now</Link>
                                                            </div>
                                                        </div>
                                                        <div className="menu-banner-discount">
                                                            <h3>
                                                                <span>25%</span>
                                                                off
                                                            </h3>
                                                        </div>*/}
                                                    </div>
                                                </li>
                                            </ul>
                                        </li>
                                        <li className="position-static">
                                            <Link to="/" >Resources <i className="fi-rs-angle-down"></i></Link>
                                            <ul className="mega-menu">
                                                <li className="sub-mega-menu sub-mega-menu-width-22">
                                                    <Link to="/" className="menu-title" >Financial Solution</Link>
                                                    <ul>
                                                        <li><Link to="/financialsolution">Working Capital</Link></li>
                                                        <li><Link to="/financialsolution">Finance for Machinery & Capital goods</Link></li>
                                                        <li><Link to="/financialsolution">Import now, Pay later</Link></li>
                                                    </ul>
                                                </li>
                                                <li className="sub-mega-menu sub-mega-menu-width-22">
                                                    <Link to="/" className="menu-title" >Procurement Solution</Link>
                                                    <ul>
                                                        <li><Link to="/procurementsolutions" >Information Gathering & Product sourcing</Link></li>
                                                        <li><Link to="/procurementsolutions" >Obtaining Competitive Quotation</Link></li>
                                                        <li><Link to="/procurementsolutions" >Conduct factory verification & supplier evaluation</Link></li>
                                                        <li><Link to="/procurementsolutions" >Sample Arrangement</Link></li>
                                                        <li><Link to="/procurementsolutions" >Quality Control and Inspection Report</Link></li>
                                                    </ul>
                                                </li>
                                                <li className="sub-mega-menu sub-mega-menu-width-22">
                                                    <Link to="/" className="menu-title" >Logistics Solution</Link>
                                                    <ul>
                                                        <li><Link to="/logisticssupport" >Shipping coordination</Link></li>
                                                        <li><Link to="/logisticssupport" >Custom Clearance Assistance</Link></li>
                                                        <li><Link to="/logisticssupport" >Import Duties and Taxes Calculation</Link></li>
                                                        <li><Link to="/logisticssupport" >Warehouse Solutions</Link></li>
                                                        <li><Link to="/logisticssupport" >Inventory Management</Link></li>
                                                        <li><Link to="/logisticssupport" >Supply Chain Optimizationh</Link></li>
                                                    </ul>
                                                </li>
                                                <li className="sub-mega-menu sub-mega-menu-width-34">
                                                    <div className="menu-banner-wrap">
                                                        <Link to="/" ><img src="assets/imgs/banner/banner-menu.png" alt="Nest" /></Link>
                                                        {/*<div className="menu-banner-content">
                                                            <h4>Hot deals</h4>
                                                            <h3>
                                                                Don't miss<br />
                                                                Trending
                                                            </h3>
                                                            <div className="menu-banner-price">
                                                                <span className="new-price text-success">Save to 50%</span>
                                                            </div>
                                                            <div className="menu-banner-btn">
                                                                <Link to="/" >Shop now</Link>
                                                            </div>
                                                        </div>
                                                        <div className="menu-banner-discount">
                                                            <h3>
                                                                <span>25%</span>
                                                                off
                                                            </h3>
                                                        </div>*/}
                                                    </div>
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                        <div className="hotline d-none d-lg-flex">
                            <div className="search-style-2">
                                <form action="#">
                                    <select className="select-active">
                                        <option>All Categories</option>
                                        <option>Petrochemicals</option>
                                        <option>Pharmaceuticals</option>
                                        <option>Specialty Chemicals</option>
                                        <option>Agrochemicals</option>
                                        <option>Polymers and Plastics</option>
                                        <option>Biochemicals</option>
                                        <option>Industrial Gases</option>
                                        <option>Fertilizers</option>
                                        <option>Mechanical Engineering</option>
                                        <option>Electrical Engineering</option>

                                        <option>Electronics Engineering</option>
                                        <option>Aerospace Engineering</option>
                                        <option>Automotive Engineering</option>
                                        <option>Chemical Engineering</option>
                                        <option>Environmental Engineering</option>
                                        <option>Industrial Engineering</option>
                                        <option>Automotive</option>
                                        <option>Electronics Manufacturing</option>
                                        <option>Textile and Apparel</option>
                                        <option>Food and Beverage Processing</option>
                                        <option>Machinery and Equipment</option>

                                        <option>Consumer Goods</option>
                                        <option>Pharmaceuticals</option>
                                        <option>Heavy Equipment</option>
                                        <option>Furniture and Wood Products</option>
                                    </select>
                                    <input type="text" placeholder="Search for items..." />
                                </form>
                            </div>
                            <div className="header-action-icon-2">
                                <ul>
                                    <li>
                                        <Link to="/" href="shop-grid-right.html">Sign In</Link>
                                    </li>
                                    <li className="main-categori-wrap">
                                        <Link to="/" href="shop-grid-right.html" className="categories-button-active">Sign Up</Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="header-action-icon-2 d-block d-lg-none">
                            <div className="burger-icon burger-icon-white">
                                <span className="burger-icon-top"></span>
                                <span className="burger-icon-mid"></span>
                                <span className="burger-icon-bottom"></span>
                            </div>
                        </div>
                        <div className="header-action-right d-block d-lg-none">
                            <div className="header-action-2">
                                <div className="header-action-icon-2">
                                    <Link to="/" href="shop-wishlist.html">
                                        <img alt="Nest" src="assets/imgs/theme/icons/icon-heart.svg" />
                                        <span className="pro-count white">4</span>
                                    </Link>
                                </div>
                                <div className="header-action-icon-2">
                                    <Link to="/" className="mini-cart-icon" >
                                        <img alt="Nest" src="assets/imgs/theme/icons/icon-cart.svg" />
                                        <span className="pro-count white">2</span>
                                    </Link>
                                    <div className="cart-dropdown-wrap cart-dropdown-hm2">
                                        <ul>
                                            <li>
                                                <div className="shopping-cart-img">
                                                    <Link to="/" ><img alt="Nest" src="assets/imgs/shop/thumbnail-3.jpg" /></Link>
                                                </div>
                                                <div className="shopping-cart-title">
                                                    <h4><Link to="/" >Plain Striola Shirts</Link></h4>
                                                    <h3><span>1 × </span>$800.00</h3>
                                                </div>
                                                <div className="shopping-cart-delete">
                                                    <Link to="/" ><i className="fi-rs-cross-small"></i></Link>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="shopping-cart-img">
                                                    <Link to="/" ><img alt="Nest" src="assets/imgs/shop/thumbnail-4.jpg" /></Link>
                                                </div>
                                                <div className="shopping-cart-title">
                                                    <h4><Link to="/" >Macbook Pro 2022</Link></h4>
                                                    <h3><span>1 × </span>$3500.00</h3>
                                                </div>
                                                <div className="shopping-cart-delete">
                                                    <Link to="/" ><i className="fi-rs-cross-small"></i></Link>
                                                </div>
                                            </li>
                                        </ul>
                                        <div className="shopping-cart-footer">
                                            <div className="shopping-cart-total">
                                                <h4>Total <span>$383.00</span></h4>
                                            </div>
                                            <div className="shopping-cart-button">
                                                <Link to="/" href="shop-cart.html">View cart</Link>
                                                <Link to="/" href="shop-checkout.html">Checkout</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    </>
  )
}

export default Navbar

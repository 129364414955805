import React from 'react'

function FinancialSolution() {
  	return (
		<>
			<section className="financial-solution position-relative">
				<h2 className="mb-30 text-center">Financial Solution</h2>
			</section>
			<div className="page-content pt-50">
				<div className="container">
					<div className="row">
						<div className="col-xl-10 col-lg-12 m-auto">
							<section className="text-center mb-50">
								<div className="row">
									<div className="col-lg-4 col-md-6 mb-24">
										<div className="featured-card">
											<img src="assets/imgs/theme/icons/icon-7.svg" alt="" />
											<h4>Working capital finance<br/><br/></h4>
											<p>With our bespoke working capital financing solutions, you can empower your company's everyday operations. Access flexible finance to manage expenditures, capitalize on growth possibilities, and maintain consistent cash flow. Our simplified approach guarantees quick access to the funds required for long-term success.</p>
										</div>
									</div>
									<div className="col-lg-4 col-md-6 mb-24">
										<div className="featured-card">
											<img src="assets/imgs/theme/icons/icon-2.svg" alt="" />
											<h4>Financing for machinery and equipment</h4>
											<p>With our specialized machinery loan solution, you can streamline the expansion of your firm. Access personalized financial alternatives designed to help you acquire equipment, update your operations, and increase efficiency. Our flexible terms and individual support make it easy to purchase necessary gear.</p>
										</div>
									</div>
									<div className="col-lg-4 col-md-6 mb-24">
										<div className="featured-card">
											<img src="assets/imgs/theme/icons/icon-3.svg" alt="" />
											<h4>Import Now, Pay Later<br/><br/></h4>
											<p>Experience the simplicity of our 'Import Now, Pay Later' service, which allows firms to buy items now and pay later. Manage cash flow while securing critical imports, streamlining your procurement process without causing immediate financial burden.<br/><br/><br/></p>
										</div>
									</div>
								</div>
							</section>
						</div>
					</div>
				</div>
			</div>
		</>
  	)
}

export default FinancialSolution

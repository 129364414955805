import React from 'react';

const About = () => {
  	return (
        <>
		<section className="aboutus position-relative">
			<h2 className="mb-30 text-center">About Us</h2>
		</section>
		<div className="page-content pt-50">
            <div className="container">
                <div className="row">
                    <div className="col-xl-10 col-lg-12 m-auto">
                        <section className="row align-items-center mb-50">
                            <div className="row mb-50 align-items-center">
                                <div className="col-lg-5">
                                    {/* <h4 className="mb-20 text-muted">Our performance</h4> */}
                                    <h1 className="heading-1 mb-40">Who we are</h1>
                                    <p className="mb-30">Procure Log India is an online B2B market place where we connect importers around the globe to Indian manufacturers and exporters and help buyers source products by using our tech-enabled marketplace and managing supply chains to bring convenience to both exporters and importers.</p>
                                    <p className="mb-30">Helping customers with the process of importing goods from India is our main objective. The skilled staff at Procure Log India handles every step of the process, from locating a supplier to negotiating with the exporter, setting up an order to sending a sample, checking the finished product to setting up logistics.</p>
									<p>With our headquarters in Mumbai, the Indian financial center, and sales offices in Delhi, Chennai, Bangalore, Kolkata, Ahmedabad, and other cities, we can use our supply chain and transportation network to expedite large-scale product procurement at a rate that is unmatched.</p>
                                </div>
								<div className="col-lg-7 pr-30">
                                    <img src="assets/imgs/page/about-10.png" alt="" className="mb-md-3 mb-lg-0 mb-sm-4" />
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>

			<div className="container">
                <div className="row">
                    <div className="col-xl-10 col-lg-12 m-auto">
                        <section className="mb-50">
                            <div className="row">
                                <div className="col-lg-4 mb-lg-0 mb-md-5 mb-sm-5">
                                    <div className="team-card">
										<img src="assets/imgs/page/about-11.png" alt="" />
									</div>
                                </div>
                                <div className="col-lg-8">
                                    <div className="row">
                                        <div className="product-info">
											<div className="tab-style3">
												<ul className="nav nav-tabs text-uppercase">
													<li className="nav-item">
														<a className="nav-link active" id="Description-tab" data-bs-toggle="tab" href="#Description">Trade Assurance</a>
													</li>
													<li className="nav-item">
														<a className="nav-link" id="Additional-info-tab" data-bs-toggle="tab" href="#Additional-info">Why Us</a>
													</li>
													<li className="nav-item">
														<a className="nav-link" id="Vendor-info-tab" data-bs-toggle="tab" href="#Vendor-info">Vision</a>
													</li>
												</ul>
												<div className="tab-content shop_info_tab entry-main-content">
													<div className="tab-pane fade show active" id="Description">
														<div className="">
															<p>All buyers are eligible for trade assurance, which means that payments are handled through our system rather than directly to the supplier. We have our own payment terms arrangement with our suppliers, which has been in effect for over a decade, in accordance with our corporate philosophy. </p>
															<p>We release agreed amount of invoice value at the time of purchase confirmation and the remaining balance at the time of delivery, ensuring that the buyer's money is safe with us. Buyer no need to block full amount in one order. Buyer even starts production of material with less than 25% of invoice value. We use our export license in a trade assurance facility to export commodities from India. The buyer paid us, and we made payments to the supplier until the order was completed. A buyer can simply add additional services such as factory verification and inspection reports when placing an order through our website.</p>
														</div>
													</div>
													<div className="tab-pane fade" id="Additional-info">
														<div>
															<p>
																We don't sell membership plans to exporters as other B2B platforms do, and exporters ultimately keep a substantial profit on the coverup membership plan that they paid the B2B platform. We provide free listing and free leads to supplier.
															</p>
															<p>
																We are the first to introduce e-auction, which gives the buyer complete control over the price at which he needs the material and gives the seller the choice to either accept or reject the bid in a single course of action.
															</p>
															<p>
																Other B2B platforms don't care who wins business—a manufacturer or a trader—and merely offer genuine leads to their paying members. We identify their companies and divide them into traders and manufacturers at Procure Log India. With only one filter in our technologically advanced marketplace, a buyer can quickly locate them.
															</p>
															<p>
																Dependency causes job delays, so manage every shipment that is ordered with the Procure Log India platform. We provide efficacy and efficiency in this way to the importer and exporter.
															</p>
														</div>
													</div>
													<div className="tab-pane fade" id="Vendor-info">
														<div>
															<p>
																With Procure Log India you can fulfill all your procurement needs streamlining the buying process and managing supply chain for convenience for both exporter and importer.
															</p>
														</div>
													</div>
												</div>
											</div>
										</div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </div>
		</>
    );
};

export default About;
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import HomeSlider from './HomeSlider';
import IndustryCarousel from './IndustryCarousel';

function Home() {
    // const $ = window.$;
    // $(".slider").not('.slick-initialized').slick()
  return (
    <>
        {/* <!-- Modal --> custom-modal */}
        <div className="modal fade custom-modal" id="onloadModal" tabIndex="-1" aria-labelledby="onloadModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-lg">
                <div className="modal-content" style={{backgroundImage: "url('assets/imgs/banner/popup-1.jpg')", backgroundSize: "100%"}}>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    <div className="modal-body">
                        <div className="deal" style={{ background: "transparent"}}>
                            <div className="deal-content detail-info">
                                <h4 className="product-title"><a href="shop-product-right.html" className="text-heading">What describe you the most</a></h4>
                            </div>
                        </div>
                        <div className="buyerSeller-Popup">
                            <div className="tab-style3">
                                <ul className="nav nav-tabs text-uppercase justify-content-center">
                                    <li className="nav-item">
                                        <a className="nav-link active" id="Buyer-tab" data-bs-toggle="tab" href="#Buyer">Buyer</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" id="Seller-tab" data-bs-toggle="tab" href="#Seller">Seller</a>
                                    </li>
                                </ul>
                                <div className="tab-content shop_info_tab entry-main-content">
                                    <div className="tab-pane fade show active" id="Buyer">
                                        <div className="single-content">
                                            <ul className="mt-30">
                                                <li>Purchase any material at your desire rate in just one click.</li>
                                                <li>Get quotation for your product from 3.2 million manufacturers.</li>
                                                <li>Upload your design and complete RFQ and start receiving quotation.</li>
                                                <li>All of your product procurement tasks, from arranging quotes to verifying suppliers and communicating with all stakeholders are handled professionally by procure log India’s Team.</li>
                                                <li>We offer in-house logistics arrangements to ensure convenience for both exporters and importers.</li>
                                                <li>We arrange inspection reports and online video meetings with buyers, sellers, and the Procure Log India team before sending materials from the plant.</li>
                                                <li>Buyers can use trade assurance to avoid supplier scams and ensure a smooth transaction. Payment will be processed through our platform; therefore, your money is safe with us.</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="Seller">
                                        <div className="single-content">
                                            <ul className="mt-30">
                                                <li>Generate high-quality leads and receive inquiries directly from interested buyers.</li>
                                                <li>Profile-based business inquiries will be forwarded to you, not to your competitors.</li>
                                                <li>Greater chance of being discovered by potential buyers and partners.</li>
                                                <li>Access to various marketing tools provided by the platform.</li>
                                                <li>Connect with a vast network of suppliers, buyers, and industry experts.</li>
                                                <li>Integrated tools for managing orders, payments, and shipping along with simplified communication channels with buyers.</li>
                                                <li>Services such as logistics, insurance, and financing offered by the platform.</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        {/* <!-- Quick view --> */}
        <div className="modal fade custom-modal" id="quickViewModal" tabIndex="-1" aria-labelledby="quickViewModalLabel" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    <div className="modal-body">
                        <div className="row">
                            <div className="col-md-6 col-sm-12 col-xs-12 mb-md-0 mb-sm-5">
                                <div className="detail-gallery">
                                    <span className="zoom-icon"><i className="fi-rs-search"></i></span>
                                    {/* <!-- MAIN SLIDES --> */}
                                    <div className="product-image-slider">
                                        <figure className="border-radius-10">
                                            <img src="assets/imgs/shop/product-16-2.jpg" alt="" />
                                        </figure>
                                        <figure className="border-radius-10">
                                            <img src="assets/imgs/shop/product-16-1.jpg" alt="" />
                                        </figure>
                                        <figure className="border-radius-10">
                                            <img src="assets/imgs/shop/product-16-3.jpg" alt="" />
                                        </figure>
                                        <figure className="border-radius-10">
                                            <img src="assets/imgs/shop/product-16-4.jpg" alt="" />
                                        </figure>
                                        <figure className="border-radius-10">
                                            <img src="assets/imgs/shop/product-16-5.jpg" alt="" />
                                        </figure>
                                        <figure className="border-radius-10">
                                            <img src="assets/imgs/shop/product-16-6.jpg" alt="" />
                                        </figure>
                                        <figure className="border-radius-10">
                                            <img src="assets/imgs/shop/product-16-7.jpg" alt="" />
                                        </figure>
                                    </div>
                                    {/* <!-- THUMBNAILS --> */}
                                    <div className="slider-nav-thumbnails">
                                        <div><img src="assets/imgs/shop/thumbnail-3.jpg" alt="" /></div>
                                        <div><img src="assets/imgs/shop/thumbnail-4.jpg" alt="" /></div>
                                        <div><img src="assets/imgs/shop/thumbnail-5.jpg" alt="" /></div>
                                        <div><img src="assets/imgs/shop/thumbnail-6.jpg" alt="" /></div>
                                        <div><img src="assets/imgs/shop/thumbnail-7.jpg" alt="" /></div>
                                        <div><img src="assets/imgs/shop/thumbnail-8.jpg" alt="" /></div>
                                        <div><img src="assets/imgs/shop/thumbnail-9.jpg" alt="" /></div>
                                    </div>
                                </div>
                                {/* <!-- End Gallery --> */}
                            </div>
                            <div className="col-md-6 col-sm-12 col-xs-12">
                                <div className="detail-info pr-30 pl-30">
                                    <span className="stock-status out-stock"> Sale Off </span>
                                    <h3 className="title-detail"><a href="shop-product-right.html" className="text-heading">Seeds of Change Organic Quinoa, Brown</a></h3>
                                    <div className="product-detail-rating">
                                        <div className="product-rate-cover text-end">
                                            <div className="product-rate d-inline-block">
                                                <div className="product-rating" style={{backgroundImage: "width: 90%"}}></div>
                                            </div>
                                            <span className="font-small ml-5 text-muted"> (32 reviews)</span>
                                        </div>
                                    </div>
                                    <div className="clearfix product-price-cover">
                                        <div className="product-price primary-color float-left">
                                            <span className="current-price text-brand">$38</span>
                                            <span>
                                                <span className="save-price font-md color3 ml-15">26% Off</span>
                                                <span className="old-price font-md ml-15">$52</span>
                                            </span>
                                        </div>
                                    </div>
                                    <div className="detail-extralink mb-30">
                                        <div className="detail-qty border radius">
                                            <a href="#" className="qty-down"><i className="fi-rs-angle-small-down"></i></a>
                                            <span className="qty-val">1</span>
                                            <a href="#" className="qty-up"><i className="fi-rs-angle-small-up"></i></a>
                                        </div>
                                        <div className="product-extra-link2">
                                            <button type="submit" className="button button-add-to-cart"><i className="fi-rs-shopping-cart"></i>Add to cart</button>
                                        </div>
                                    </div>
                                    <div className="font-xs">
                                        <ul>
                                            <li className="mb-5">Vendor: <span className="text-brand">Nest</span></li>
                                            <li className="mb-5">MFG:<span className="text-brand"> Jun 4.2022</span></li>
                                        </ul>
                                    </div>
                                </div>
                                {/* <!-- Detail Info --> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <main className="main">
            <section className="home-slider position-relative mb-30">
                <div className="container">
                    <div className="home-slide-cover mt-30">
                        <HomeSlider /> 
                    </div>
                </div>
            </section>

            <section className="banners mb-25 mt-40">
                <div className="container">
                    <div className="row">
                        <div className="section-title">
                            <div className="title">
                                <h3>Business Solutions Tailored for You</h3>
                            </div>
                            <div className="slider-arrow slider-arrow-2 flex-right carausel-10-columns-arrow" id="carausel-10-columns-arrows"></div>
                        </div>

                        <div className="col-lg-4 d-md-none d-lg-flex">
                            <a href="#">
                            <div className="banner-img mb-sm-0 wow animate__animated animate__fadeInUp" data-wow-delay=".4s">
                                <img src="assets/imgs/banner/banner-6.png" alt="" />
                                <div className="banner-text">
                                    <h4>My Price My Bid</h4>
                                    <p>
                                        Lets buyers set a target price, and sellers respond directly, skipping negotiations for a faster deal.
                                    </p>
                                </div>
                            </div>
                            </a>
                        </div>

                        <div className="col-lg-4 col-md-6 mb-15">
                            <a href="#">
                                <div className="banner-img wow animate__animated animate__fadeInUp" data-wow-delay=".2s">
                                    <img src="assets/imgs/banner/banner-2.png" alt="" />
                                    <div className="banner-text">
                                        <h4>
                                            International Supplier Branding
                                        </h4>
                                        <p>
                                            Craft strategies for global market expansion, ensuring consistent brand identity across regions.
                                        </p>
                                    </div>
                                </div>
                            </a>
                        </div>

                        <div className="col-lg-4 col-md-6 mb-15">
                            <a href="#">
                                <div className="banner-img wow animate__animated animate__fadeInUp" data-wow-delay="0">
                                    <img src="assets/imgs/banner/banner-1.png" alt="" />
                                    <div className="banner-text">
                                        <h4>
                                           B2B Marketplace
                                        </h4>
                                        <p>
                                            Enable companies to easily discover, connect, and transact with other businesses on a single platform. 
                                        </p>
                                    </div>
                                </div>
                            </a>
                        </div>

                        <div className="col-lg-4 col-md-6">
                            <a href="#">
                                <div className="banner-img wow animate__animated animate__fadeInUp" data-wow-delay="0">
                                    <img src="assets/imgs/banner/banner-4.png" alt="" />
                                    <div className="banner-text">
                                        <h4>
                                            Turnkey Projects
                                        </h4>
                                        <p>
                                            Helps clients find the best professionals with proven track records in their respective industries, offering end-to-end management from planning to execution for seamless project delivery. 
                                        </p>
                                    </div>
                                </div>
                            </a>
                        </div>

                        <div className="col-lg-4 col-md-6">
                            <a href="#">
                                <div className="banner-img wow animate__animated animate__fadeInUp" data-wow-delay=".2s">
                                    <img src="assets/imgs/banner/banner-5.png" alt="" />
                                    <div className="banner-text">
                                        <h4>
                                            Sourcing Solutions
                                        </h4>
                                        <p>
                                            Streamlines procurement with RFQ submissions and direct seller communication for faster, transparent negotiations and offers.
                                        </p>
                                    </div>
                                </div>
                            </a>
                        </div>
                        
                        <div className="col-lg-4 d-md-none d-lg-flex mb-15">
                            <a href="#">
                            <div className="banner-img mb-sm-0 wow animate__animated animate__fadeInUp" data-wow-delay=".4s">
                                <img src="assets/imgs/banner/banner-3.png" alt="" />
                                <div className="banner-text">
                                    <h4>Logistics</h4>
                                    <p>
                                        Logistics services offer real-time quotes, a freight calculator for cost estimates, and online tracking for live shipment updates, making shipping more efficient and transparent.
                                    </p>
                                </div>
                            </div>
                            </a>
                        </div>
                    </div>
                </div>
            </section>
            
            <section className="popular-categories section-padding">
                <div className="container">
                    <IndustryCarousel />
                </div>
            </section>
        </main>
    </>
  )
}

export default Home;

import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";

function HomeSlider() {
    const settings = {
        slidesToShow: 1,
        slidesToScroll: 1,
        fade: true,
        infinite: true, // enables looping
        dots: true,
        arrows: true,
        prevArrow: <span className="slider-btn slider-prev"><FiChevronLeft /></span>,
        nextArrow: <span className="slider-btn slider-next"><FiChevronRight /></span>,
        autoplay: true,
        appendDots: dots => (
            <div style={{ position: "absolute", bottom: "-30px" }}>
                <ul> {dots} </ul>
            </div>
        )
    };

return (
    <>
        <div className="hero-slider-1 style-4 dot-style-1 dot-style-1-position-1">
            <Slider {...settings}>
                {/* Slide 1 */}
                <div className="slide carausel-8-columns-cover">
                    <div 
                        className="single-hero-slider single-animation-wrap" 
                        style={{ backgroundImage: "url(assets/imgs/slider/slider-1.jpg)" }}
                    >
                        <div className="slider-content">
                            <h1 className="display-2 mb-20">
                                International Supplier  <br />
                                Branding
                            </h1>
                            <p className="mb-65">Take Your Brand Global - Consistency Across Continents!</p> 
                        </div>
                    </div>
                </div>
                {/* Slide 2 */}
                <div className="slide">
                    <div 
                        className="single-hero-slider single-animation-wrap" 
                        style={{ backgroundImage: "url(assets/imgs/slider/slider-2.jpg)" }}
                    >
                        <div className="slider-content">
                            <h1 className="display-2 mb-40">
                                B2B Marketplace <br />
                            </h1>
                            <p className="mb-65">Discover, Connect, and Grow - All on One B2B platform!</p> 
                        </div>
                    </div>
                </div>
                {/* Slide 3 */}
                <div className="slide">
                    <div 
                        className="single-hero-slider single-animation-wrap" 
                        style={{ backgroundImage: "url(assets/imgs/slider/slider-3.jpg)" }}
                    >
                        <div className="slider-content">
                            <h1 className="display-2 mb-40">
                                Turnkey Projects
                            </h1>
                            <p className="mb-65">Your Vision, Our Expertise – Complete Project Solutions from Start to Finish!</p> 
                        </div>
                    </div>
                </div>
                {/* Slide 4 */}
                <div className="slide">
                    <div 
                        className="single-hero-slider single-animation-wrap" 
                        style={{ backgroundImage: "url(assets/imgs/slider/slider-4.jpg)" }}
                    >
                        <div className="slider-content">
                            <h1 className="display-2 mb-40">
                                Sourcing Solutions
                            </h1>
                            <p className="mb-65">Easy RFQ Submissions and Transparent Negotiations – Source Smarter!</p> 
                        </div>
                    </div>
                </div>
                {/* Slide 5 */}
                <div className="slide">
                    <div 
                        className="single-hero-slider single-animation-wrap" 
                        style={{ backgroundImage: "url(assets/imgs/slider/slider-5.jpg)" }}
                    >
                        <div className="slider-content">
                            <h1 className="display-2 mb-40">
                                Logistics
                            </h1>
                            <p className="mb-65">Real-Time Quotes, Real-Time Tracking – Logistics You Can Count On!</p> 
                        </div>
                    </div>
                </div>
                {/* Slide 6 */}
                <div className="slide">
                    <div 
                        className="single-hero-slider single-animation-wrap" 
                        style={{ backgroundImage: "url(assets/imgs/slider/slider-6.jpg)" }}
                    >
                        <div className="slider-content">
                            <h1 className="display-2 mb-40">
                                Discover Opportunities <br />
                                Worldwide
                            </h1>
                            <p className="mb-65">Join us today!</p> 
                        </div>
                    </div>
                </div>

                {/* Slide 7 */}
                <div className="slide">
                    <div 
                        className="single-hero-slider single-animation-wrap" 
                        style={{ backgroundImage: "url(assets/imgs/slider/slider-7.jpg)" }}
                    >
                        <div className="slider-content">
                            <h1 className="display-2 mb-40">
                                My bid My price
                            </h1>
                            <p className="mb-65">Empower Your Procurement: Start Buyer-Driven Auctions Today!</p> 
                        </div>
                    </div>
                </div>

            </Slider>
        </div>
                        </>
  );
}

export default HomeSlider;


